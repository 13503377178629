import { Branch } from "mkm-types";

const useBranchCurrentUser = () => {
  const { merged } = useCart();
  const { user } = useUser();
  const { selectedBranchId, fetchAccountBranch, selectBranch } = useBranches();
  const { setBranchSwitchTray, setMergeWarning } = useUIState();

  const isSelectedBranch = (branchId: Branch["id"]) => String(branchId) === String(selectedBranchId.value);

  const selectBranchFromCurrentUser = async () => {
    nextTick(async () => {
      if (user.value) {
        if (selectedBranchId.value && !isSelectedBranch(String(user.value?.account.branch_id))) {
          await fetchAccountBranch();

          setBranchSwitchTray(true);
        } else {
          selectBranch(user.value?.account.branch_id);

          if (merged.value) {
            setMergeWarning(true, Number(selectedBranchId.value));
          }
        }
      }
    });
  };

  return {
    selectBranchFromCurrentUser,
  };
};

export default useBranchCurrentUser;
